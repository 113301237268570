<template>
    <a-modal :width="600" title="申诉" :visible.sync="visible" @cancel="handleCancel">
        <a-form-model style="width: 90%;margin: 0 auto;" ref="formRef" :model="formData" :colon="true" :labelCol="{
            xs: { span: 24 },
            sm: { span: 4 },
        }" :wrapper-col="{ span: 20 }" :rules="rules">
            <a-form-model-item label="原因" prop="AppealContent">
                <a-textarea :autoSize="{ minRows: 4 }" v-model="formData.AppealContent"
                    placeholder="请阐述申诉事实及依据，不超过500字" :maxLength="500"></a-textarea>
            </a-form-model-item>
            <a-form-model-item label="凭证">
                <TransferVoucherUpload v-if="visible" :fileList.sync="formData.transferVouchers" :fileLength="5">
                </TransferVoucherUpload>
            </a-form-model-item>
        </a-form-model>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" :loading="isSubmitting" :disabled="isSubmitting" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" class="confirm-btn" :disabled="loading || isSubmitting" :loading="loading || isSubmitting" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { watch, ref } from 'vue-demi';
import TransferVoucherUpload from '../../../projectMember/components/transferVoucherUpload.vue';
import { appealMyGoodFaithBehavior } from '../../../../api/goodfaithclause';
import { message } from 'ant-design-vue';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        GoodFaithBehaviorId: {
            type: String | undefined,
            required: true
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit("update:visible", false);
        };
        const formRef = ref(null);
        const formData = ref({
            AppealContent: "",
            transferVouchers: []
        });
        const rules = ref({
            AppealContent: [{
                required: true,
                message: `请输入`
            }]
        });
        const loading = ref(false);
        watch(() => props.visible, async (value) => {
            if(value) {
            formRef.value && formRef.value.resetFields();   
            }
            
        })
        const isSubmitting = ref(false);

        const handleConfirm = async () => {
            isSubmitting.value = true;
            formRef.value && formRef.value.validate(async (valid) => {
                if (valid) {
                    //return;
                    loading.value = true;
                    const dataForm = new FormData();
                    dataForm.append('GoodFaithBehaviorId',props.GoodFaithBehaviorId);
                    dataForm.append('AppealContent', formData.value.AppealContent);
                    formData.value.transferVouchers && formData.value.transferVouchers.forEach(item => {
                        dataForm.append('FormFileList', item.file);
                    })
                    //dataForm.append('FormFileList[]', formData.value.transferVouchers && formData.value.transferVouchers.length > 0 ? formData.value.transferVouchers.map(item => item.file) : []);
                  /*   const res = await appealMyGoodFaithBehavior({
                        goodFaithBehaviorId: props.GoodFaithBehaviorId,
                        appealContent: formData.value.AppealContent,
                        appealPicOne: formData.value.transferVouchers.length > 0 ? formData.value.transferVouchers[0].thumbUrl : undefined,
                        appealPicTwo: formData.value.transferVouchers.length > 1 ? formData.value.transferVouchers[1].thumbUrl : undefined,
                        appealPicThree: formData.value.transferVouchers.length > 2 ? formData.value.transferVouchers[2].thumbUrl : undefined,
                        appealPicFour: formData.value.transferVouchers.length > 3 ? formData.value.transferVouchers[3].thumbUrl : undefined,
                        appealPicFive: formData.value.transferVouchers.length > 4 ? formData.value.transferVouchers[4].thumbUrl : undefined,
                    }); */
                    const res = await appealMyGoodFaithBehavior(dataForm)
                    if (res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        context.emit('onAppeal')
                        context.emit('update:visible', false);
                    }
                    loading.value = false;
                }
                isSubmitting.value = false;
            })
            !formRef.value && (isSubmitting.value = false)
        }
        return {
            handleCancel,
            formRef,
            formData,
            rules,
            loading,
            handleConfirm,
            isSubmitting
        };
    },
    components: { TransferVoucherUpload }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>
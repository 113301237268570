<template>
    <a-modal :width="600" title="条款详情" :visible.sync="visible" @cancel="handleCancel" :footer="null">
        <a-spin :spinning="loading" tip="正在加载中...">
            <div v-show="loading" style="height: 200px;"></div>
            <div style="max-height: 50vh; overflow-y: auto;">
                <div v-html="content"></div>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { watch, ref } from 'vue-demi';
import { getGoodFaithDetail } from '../../../../api/goodfaithclause';
import { message } from 'ant-design-vue';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        GoodFaithBehaviorId: {
            type: String | undefined,
            required: true
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit("update:visible", false);
        };
        const content = ref('');
        const loading = ref(false);
        watch(() => props.visible, async (value) => {
            if(value) {
                loading.value = true;
                const res = await getGoodFaithDetail({
                    Id: props.GoodFaithBehaviorId
                });
                if(res.code === 200) {
                    content.value = res.data;
                }
                else {
                    message.error(res.message);
                }
                loading.value = false;
            }
            
        })
   
        return {
            handleCancel,
            loading,
            content
        };
    }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>
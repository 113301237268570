import { render, staticRenderFns } from "./goodFaithContextModal.vue?vue&type=template&id=65a64936&scoped=true&"
import script from "./goodFaithContextModal.vue?vue&type=script&lang=js&"
export * from "./goodFaithContextModal.vue?vue&type=script&lang=js&"
import style0 from "./goodFaithContextModal.vue?vue&type=style&index=0&id=65a64936&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a64936",
  null
  
)

export default component.exports
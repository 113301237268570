<template>
    <div class="my-good-faith-bahavior-ctn">
        <div class="faith-head">
            <div class="faith-title">
                我的诚信分
            </div>
            <div class="my-faith-score">
                我的诚信分：{{ mygarde }}
            </div>
        </div>
        <div class="search-operations">
            <div class="search-operation">
                <a-range-picker style="width: 100%;" value-format="YYYY-MM-DD" v-model="dateRange"></a-range-picker>
            </div>
            <div class="search-operation">
                <a-select placeholder="请选择状态" style="width: 100%;" v-model="State" allowClear>
                    <a-select-option v-for="(item, index) in goodFaithAppealTypes" :key="item.value" :value="item.value">
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </div>
            <div class="search-operation">
                <a-input placeholder="请输入实验编号" v-model="ExperimentNo" allowClear></a-input>
            </div>
            <div class="search-btn">
                <a-button type="primary" class="search-btn" @click="handleSearch"> 查询
                </a-button>
            </div>
        </div>
        <div class="table-ctn sTable">
            <a-table rowKey="id" :columns="columns" :data-source="list" :pagination="false" :scroll="{ x: 1100, y: tableHeight }"
                :loading="loading">
                <template slot="index" slot-scope="text, record, index">
                    {{ index + 1 }}
                </template>
                <template slot="gTitle" slot-scope="text, record, index">
                    <a @click="handleCheckGoodFaithContextClick(record)"> {{ text }} </a>
                </template>
                <template slot="state" slot-scope="text, record, index">
                    <span :style="{color: getGoodFaithAppealTypeColor(record.state)}"> {{ getGoodFaithAppealTypeName(record.state) }} </span>
                </template>
                <template slot="operation" slot-scope="text, record, index">
                    <a class="operation-btn" v-if="record.hasSubmit === 1 && record.state != 1 && record.state != 2 " @click="handleAppealButtonClick(record)"> 申诉 </a>
                    <a-tooltip v-else-if="record.hasSubmit === 2 && record.state == 4" title="超过期限，不可申诉">
                        <span  class="operation-btn">
                        <a  disabled> 申诉 </a>
                        </span>
                    </a-tooltip>
                    <a class="operation-btn" @click="handleGoodFaithDetailClick(record)" v-if="record.state != 4"> 详情 </a>
                </template>
            </a-table>
        </div>
        <div class="default-pagination">
            <a-pagination size="small" @change="handlePageChange" v-model="current" :pageSize="10" :total="total"
                show-less-items />
        </div>
        <GoodFaithAppealModal :visible.sync="goodFaithAppealModalVisible" :GoodFaithBehaviorId.sync="appealingGoodFaithItemId" @onAppeal="onAppeal"></GoodFaithAppealModal>
        <GoodFaithDetailModal :visible.sync="goodFaithDetailModalVisible" :id="goodFaithDetailId"></GoodFaithDetailModal>
        <GoodFaithContextModal :visible.sync="goodFaithContextModalVisible" :GoodFaithBehaviorId.sync="goodFaithContextId"></GoodFaithContextModal>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue-demi';
const tableHeight = document.documentElement.clientHeight - 550;
import { getMyGoodFaithBehavior } from '../../../api/goodfaithclause';
import { goodFaithAppealTypes, getGoodFaithAppealTypeName, getGoodFaithAppealTypeColor } from '../../../utils/enums';
import GoodFaithAppealModal from './components/goodFaithAppealModal.vue';
import GoodFaithDetailModal from './components/goodFaithDetailModal.vue';
import GoodFaithContextModal from './components/goodFaithContextModal.vue';
const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        scopedSlots: { customRender: "index" }
    },
    {
        title: '扣分条款',
        dataIndex: 'title',
        scopedSlots: { customRender: "gTitle" }
    },
    {
        title: '扣分人',
        dataIndex: 'aduitName'
    },
    {
        title: '诚信分',
        dataIndex: 'grade'
    },
    {
        title: '扣分时间',
        dataIndex: 'aduitTime'
    },
    {
        title: '实验编号',
        dataIndex: 'experimentNo'
    },
    {
        title: '实验名称',
        dataIndex: 'experimentName'
    },
    {
        title: '状态',
        scopedSlots: { customRender: "state" }
    },
    {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: 150,
        fixed: "right",
        scopedSlots: { customRender: "operation" }
    }
]
export default {
    props: {},
    setup(props, context) {
        // 起始时间
        const dateRange = ref([]);
        // 加载状态
        const loading = ref(false);
        // 申诉状态
        const State = ref(undefined);
        // 实验编号
        const ExperimentNo = ref(context.root.$route.query.ExperimentNo ? context.root.$route.query.ExperimentNo : '');
        // 搜索
        const handleSearch = () => {
            current.value = 1;
            getBehaviorList();
        };
        // 页数
        const current = ref(1);
        // 总数
        const total = ref(0);
        // 数据
        const list = ref([]);
        // 我的分数
        const mygarde = ref("");
        // 获取诚信行为列表
        const getBehaviorList = async () => {
            loading.value = true;
            const res = await getMyGoodFaithBehavior({
                StartTime: dateRange.value.length === 2 ? dateRange.value[0] : "",
                EndTime: dateRange.value.length === 2 ? dateRange.value[1] : "",
                ExperimentNo: ExperimentNo.value,
                State: State.value,
                PageSize: 10,
                PageNum: current.value
            });
            if (res.code === 200) {
                loading.value = false;
                total.value = res.data.count;
                mygarde.value = res.data.mygarde;
                list.value = res.data.info;
            }
        };
        const handlePageChange = (page, pageSize) => {
            getBehaviorList();
        };
        onMounted(() => {
            getBehaviorList();
        });
        /***********************             申诉 start                **************************/
        // 申诉对话框
        const goodFaithAppealModalVisible = ref(false);
        // 申诉中的诚信行为
        const appealingGoodFaithItem = {};
        // 申诉中的诚信行为id
        const appealingGoodFaithItemId = ref(undefined);
        // 申诉按钮点击事件
        const handleAppealButtonClick = (record) => {
            appealingGoodFaithItem.value = record;
            appealingGoodFaithItemId.value = record.id;
            goodFaithAppealModalVisible.value = true;
        };
        // 申诉请求成功回调
        const onAppeal = () => {
            getBehaviorList();
        }
        /***********************             申诉 end                  **************************/
        /***********************             申诉详情 start                  **************************/
        // 申诉详情对话框可见变量
        const goodFaithDetailModalVisible = ref(false);
        // 获取申诉详情的id
        const goodFaithDetailId = ref(null);
        // 申诉详情点击事件
        const handleGoodFaithDetailClick = (record) => {
            goodFaithDetailId.value = record.id;
            goodFaithDetailModalVisible.value = true;
        }
        /***********************             申诉详情   end                  **************************/
        /***********************             查看条款详情 start              ***************************/
        const goodFaithContextModalVisible = ref(false);

        const goodFaithContextId = ref(null);

        const handleCheckGoodFaithContextClick = (record) => {
            goodFaithContextId.value = record.id;
            goodFaithContextModalVisible.value = true;
        }
        /***********************             查看条款详情 end                ***************************/
        return {
            dateRange,
            tableHeight,
            loading,
            State,
            ExperimentNo,
            handleSearch,
            handlePageChange,
            current,
            total,
            goodFaithAppealTypes,
            mygarde,
            columns,
            list,
            getGoodFaithAppealTypeName,
            goodFaithAppealModalVisible,
            appealingGoodFaithItem,
            handleAppealButtonClick,
            onAppeal,
            appealingGoodFaithItemId,
            getGoodFaithAppealTypeColor,
            goodFaithDetailModalVisible,
            goodFaithDetailId,
            handleGoodFaithDetailClick,
            goodFaithContextModalVisible,
            goodFaithContextId,
            handleCheckGoodFaithContextClick
        };
    },
    components: { GoodFaithAppealModal, GoodFaithDetailModal, GoodFaithContextModal }
}
</script>

<style lang="less" scoped>
.my-good-faith-bahavior-ctn {
    .faith-head {
        display: flex;

        .faith-title {
            font-size: 18px;
            color: rgba(0, 0, 0, 0.85);
            text-align: left;
            border-bottom: 1px solid #EBEBEB;
            margin: 0 16px;
            padding: 16px 0;
            margin-bottom: 16px;
            font-weight: 550;
            width: 0;
            flex-grow: 1;
        }

        .my-faith-score {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
            text-align: left;
            margin: 0 48px 0 48px;
            padding: 16px 0;
            margin-bottom: 16px;
            font-weight: 550;
        }
    }

    .search-operations {
        display: flex;
        padding: 0px 16px;
        width: 100%;

        .search-operation {
            width: 20%;
        }

        .search-operation+.search-operation {
            margin-left: 24px;
        }

        .search-btn {
            margin-left: 32px;
        }
    }

    .table-ctn {
        min-height: calc(100vh - 490px);
        margin-top: 16px;
        .operation-btn  + .operation-btn {
            margin-left: 16px;
        } 

        /*         /deep/ .ant-table-thead {
            background: #F0F5FF;
        }

        /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
            margin: 0px;
        }

        /deep/ .ant-table-row {
            td {
                padding: 7px;
            }
        } */

    }

    .default-pagination {
        text-align: center;
        margin-bottom: 24px;
    }
}
.sTable {
    /deep/  .ant-table {
		line-height: 1 !important;
		font-size: 14px !important;
	}
    /deep/ .ant-table-thead {
		line-height: 0.1;
	}
    /deep/ .ant-table-body {
		line-height: 1.5;
	}
    /deep/ .ant-table-thead th {
		background: #F0F5FF !important;
		/* height: 10px; */
		color: rgba(0, 0, 0, 0.85) !important;
		border: 0 !important;
	}
}
</style>